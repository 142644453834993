import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
} from '@backstage/theme';
import { Colors } from '../constants';

const { BLACK, YELLOW, WHITE, PURPLE_OP, GREY } = Colors;

type StyleObject = Record<string, any>;

interface ComponentStyles {
    styleOverrides: StyleObject;
}

const generateComponentStyles = (styles: StyleObject): ComponentStyles => ({
    styleOverrides: { ...styles }
});

export const defaultTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
        palette: {
            ...palettes.light,
            primary: {
                main: BLACK,
            },
            secondary: {
                main: PURPLE_OP,
            },
            error: {
                main: '#8c4351',
            },
            warning: {
                main: '#8f5e15',
            },
            info: {
                main: '#34548a',
            },
            success: {
                main: '#485e30',
            },
            background: {
                default: '#FFF',
                paper: WHITE,
            },
            banner: {
                info: '#34548a',
                error: '#8c4351',
                text: YELLOW,
                link: '#565a6e',
            },
            link: BLACK,
            errorBackground: '#8c4351',
            warningBackground: '#8f5e15',
            infoBackground: YELLOW,
            navigation: {
                background: YELLOW,
                indicator: GREY,
                color: BLACK,
                selectedColor: GREY,
                navItem: {
                    hoverBackground: GREY,
                    linkHover: YELLOW
                }
            },
        },
    }),
    defaultPageTheme: 'home',
    fontFamily: 'Comic Sans MS',
    /* below drives the header colors */
    pageTheme: {
        home: genPageTheme({ colors: ['#8c4351', YELLOW], shape: shapes.wave }),
        documentation: genPageTheme({
            colors: ['#8c4351', YELLOW],
            shape: shapes.wave2,
        }),
        tool: genPageTheme({ colors: ['#8c4351', YELLOW], shape: shapes.round }),
        service: genPageTheme({
            colors: ['#8c4351', YELLOW],
            shape: shapes.wave,
        }),
        website: genPageTheme({
            colors: ['#8c4351', YELLOW],
            shape: shapes.wave,
        }),
        library: genPageTheme({
            colors: ['#8c4351', YELLOW],
            shape: shapes.wave,
        }),
        other: genPageTheme({ colors: ['#8c4351', YELLOW], shape: shapes.wave }),
        app: genPageTheme({ colors: ['#8c4351', YELLOW], shape: shapes.wave }),
        apis: genPageTheme({ colors: ['#8c4351', YELLOW], shape: shapes.wave }),
    },
    components: {
        BackstageHeader: {
            styleOverrides: {
                header: () => ({
                    backgroundImage: 'unset',
                    background: WHITE,
                    borderBottom: `1px solid ${BLACK}`,
                    boxShadow: 'unset',
                }),
                title: () => ({
                    color: BLACK,
                    fontWeight: 900,
                }),
                subtitle: () => ({
                    color: BLACK,
                }),
                type: () => ({
                    color: BLACK,
                }),
            },
        },
        MuiCard: generateComponentStyles({ root: { background: GREY } }),
        MuiCardActions: generateComponentStyles({ root: { color: WHITE } }),
        MuiCardContent: generateComponentStyles({ root: { color: BLACK } }),
        BackstageItemCardHeader: generateComponentStyles({
            root: {
                backgroundImage: 'unset !important',
                color: `${BLACK} !important`,
                background: YELLOW
            }
        }),
        BackstageSidebarItem: generateComponentStyles({
            selected: {
                color: `${BLACK} !important`,
                background: GREY,
            }
        }),
        BackstageHeaderLabel: generateComponentStyles({
            value: { color: `${BLACK} !important` },
            label: { color: `${BLACK} !important` }
        }),
        MuiIconButton: generateComponentStyles({ root: { color: `${BLACK} !important` } })
    }
});