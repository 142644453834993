import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    gitlabAuthApiRef,
    identityApiRef,
    storageApiRef
} from '@backstage/core-plugin-api';
import { ScmAuth, ScmIntegrationsApi, scmAuthApiRef, scmIntegrationsApiRef } from '@backstage/integration-react';
import {
    VisitsStorageApi,
    visitsApiRef,
} from '@backstage/plugin-home';
import { techRadarApiRef } from "@backstage/plugin-tech-radar";
import { TechRadarClient } from "./lib/TechRadarClient";

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: visitsApiRef,
        deps: {
            storageApi: storageApiRef,
            identityApi: identityApiRef,
        },
        factory: ({ storageApi, identityApi }) =>
            VisitsStorageApi.create({ storageApi, identityApi }),
    }),
    createApiFactory({
        api: techRadarApiRef,
        deps: { gitlabAuthApi: gitlabAuthApiRef },
        factory: ({ gitlabAuthApi }) => new TechRadarClient(gitlabAuthApi),
    }),
    createApiFactory({
        api: scmAuthApiRef,
        deps: {
            gitlabAuthApi: gitlabAuthApiRef,
        },
        factory: ({ gitlabAuthApi }) => ScmAuth.forGitlab(gitlabAuthApi),
    }),
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    })
];
