import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {orgPlugin} from '@backstage/plugin-org';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {TechDocsIndexPage, techdocsPlugin, TechDocsReaderPage,} from '@backstage/plugin-techdocs';
import {ReportIssue} from '@backstage/plugin-techdocs-module-addons-contrib';
import {TechDocsAddons} from '@backstage/plugin-techdocs-react';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import React from 'react';
import {Route} from 'react-router-dom';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {Root} from './components/Root';
import {searchPage} from './components/search/SearchPage';

import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {gitlabAuthApiRef} from "@backstage/core-plugin-api";
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {CatalogGraphPage} from '@backstage/plugin-catalog-graph';
import {VisitListener} from '@backstage/plugin-home';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {HomePage} from './components/home/HomePage';
import {UnifiedThemeProvider} from '@backstage/theme';
import {defaultTheme} from './theme/defaultTheme';

import LightIcon from '@material-ui/icons/WbSunny';


const authProviders = [
    {
        id: 'gitlab-auth-provider',
        title: 'Gitlab',
        message: 'Login via Gitlab',
        apiRef: gitlabAuthApiRef,
    },
]

const app = createApp({
    apis,
    components: {
        SignInPage: props => {
            return (
                <SignInPage
                    {...props}
                    providers={authProviders}
                    align="center"
                />
            );
        },
    },
    bindRoutes({bind}) {
        bind(catalogPlugin.externalRoutes, {
            createComponent: scaffolderPlugin.routes.root,
            viewTechDoc: techdocsPlugin.routes.docRoot,
        });
        bind(apiDocsPlugin.externalRoutes, {
            registerApi: catalogImportPlugin.routes.importPage,
        });
        bind(scaffolderPlugin.externalRoutes, {
            registerComponent: catalogImportPlugin.routes.importPage,
        });
        bind(orgPlugin.externalRoutes, {
            catalogIndex: catalogPlugin.routes.catalogIndex,
        });
    },
    themes: [{
        id: 'Default Theme',
        title: 'Default Theme',
        variant: 'light',
        icon: <LightIcon/>,
        Provider: ({children}) => (
            <UnifiedThemeProvider theme={defaultTheme} children={children}/>
        ),
    }]
});

const routes = (
    <FlatRoutes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/catalog" element={<CatalogIndexPage/>}/>
        <Route
            path="/catalog/:namespace/:kind/:name"
            element={<CatalogEntityPage/>}>
            {entityPage}
        </Route>
        <Route path="/docs" element={<TechDocsIndexPage/>}/>
        <Route
            path="/docs/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage/>}>
            <TechDocsAddons>
                <ReportIssue/>
            </TechDocsAddons>
        </Route>
        <Route path="/create" element={<ScaffolderPage/>}/>
        <Route path="/api-docs" element={<ApiExplorerPage/>}/>
        <Route
            path="/tech-radar"
            element={<TechRadarPage width={1500} height={800} title="Skills Radar"
                                    subtitle={"We should aim for at least 'Novice' level when working on a project."}
                                    pageTitle="Cloud & DevOps"/>}/>
        <Route
            path="/catalog-import"
            element={
                <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage/>
                </RequirePermission>
            }
        />
        <Route path="/search" element={<SearchPage/>}>
            {searchPage}
        </Route>
        <Route path="/settings" element={<UserSettingsPage/>}/>
        <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
        <Route path="/homepage" element={<HomePage/>}/>
    </FlatRoutes>
);

export default app.createRoot(
    <>
        <AlertDisplay/>
        <OAuthRequestDialog/>
        <AppRouter>
            <VisitListener/>
            <Root>{routes}</Root>
        </AppRouter>
    </>,
);
